import { useEffect } from 'react'

interface ScriptAttribute {
  name: string
  value: string
}

interface ScriptProps {
  parentElementId: string
  url: string
  attributes?: ScriptAttribute[]
}


const useScript = ({parentElementId, url, attributes}: ScriptProps) => {
  useEffect(() => {
    const script = document.createElement('script')

    if (attributes) {
      attributes.forEach((attr: ScriptAttribute) => {
        script.setAttribute(attr.name, attr.value)
      })
    }

    script.src = url
    script.async = true
    document.getElementById(parentElementId).appendChild(script)

    return () => {
      try {
        document.getElementById(parentElementId)?.removeChild(script)
      } catch (e) {
        // swallow error okay?
      }
    }
  }, [url])
}

export default useScript
