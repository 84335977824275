import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Theme } from '@material-ui/core'
import useScript from './hooks/useScript'
import { loadConvertKitConfig } from '../config-loaders'

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    paddingTop: 100,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const AboutSignupForm = () => {
  const ck = loadConvertKitConfig()
  console.log(`convertkit config: ${JSON.stringify(ck)}`)
  const url = `https://${ck.domain}/${ck.dataUid}/index.js`

  return (
    // Makes element like: <script async data-uid={ck.dataUid} src="https://{ck.domain}/{ck.dataUid}/index.js"></script>
    <SignupForm
      parentElementId="aboutSignupForm"
      dataUid={ck.dataUid}
      formUrl={url}
    />
  )
}

interface SignupFormProps {
  parentElementId: string
  dataUid: string
  formUrl: string
}

const SignupForm = ({ parentElementId, dataUid, formUrl }: SignupFormProps) => {
  useScript({parentElementId: parentElementId, url: formUrl, attributes: [{name: 'data-uid', value: dataUid}]})

  const classes = useStyles()

  return (
    <Grid container className={classes.formContainer}>
      <Grid id={parentElementId} item />
    </Grid>
  )
}
