import { ConvertKitConfig, RecaptchaConfig, SanityConfig } from './custom-types'

export const loadConvertKitConfig = (): ConvertKitConfig => {
  return {
    dataUid: process.env.GATSBY_CONVERT_KIT_NEWLETTER_SIGNUP_DATA_UID,
    domain: process.env.GATSBY_CONVERT_KIT_DOMAIN,
  }
}

export const loadSanityConfig = (): SanityConfig => {
  return {
    apiVersion: process.env.SANITY_API_VERSION,
    dataset: process.env.SANITY_DATASET,
    overlayDrafts: process.env.SANITY_OVERLAY_DRAFTS === 'true',
    projectId: process.env.SANITY_PROJECT_ID,
    roToken: process.env.SANITY_RO_TOKEN,
    rwToken: process.env.SANITY_RW_TOKEN,
    watchMode: process.env.SANITY_WATCH_MODE === 'true'
  }
}

export const loadRecaptchaConfig = (): RecaptchaConfig => {
  return {
    siteKey: process.env.GATSBY_GOOGLE_RECAPTCHA_V3_SITE_KEY,
    secretKey: process.env.GOOGLE_RECAPTCHA_V3_SECRET_KEY,
  }
}

export const loadTypeKitId = (): string => process.env.TYPEKIT_ID
