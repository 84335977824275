import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GraphqlResults } from '../support-types'
import SEO from '../components/SEO'
import NarrativeRow, { ImagePosition } from '../components/NarrativeRow'
import { AboutSignupForm } from '../components/SignupForm'
import { Link } from 'gatsby'

const balloonBook = (
  <StaticImage
    src="../images/balloonBook.jpg"
    alt="book with emerging hot air balloon"
    width={250}
  />
)

const bookStackWithOpenBook = (
  <StaticImage
    src="../images/bookstack_with_open_book.jpg"
    alt={'bookstack with open book'}
    width={275}
  />
)

const booksWithCoffeeCup = (
  <StaticImage
    src="../images/books_with_coffee_cup.jpg"
    alt="books with coffee cup"
    width={250}
  />
)

const bookStackImage = (
  <StaticImage src="../images/bookStack.jpg" alt="stack of books" width={300} />
)

const boyHikerOnBook = (
  <StaticImage
    src="../images/boy_hiker_on_book_pages.jpg"
    alt="boy hiker on book pages"
    width={160}
  />
)

const balloonBookImage = (
  <StaticImage
    src="../images/balloonBook.jpg"
    alt="book with a balloon"
    width={160}
  />
)

const AboutPage = ({ data, pageContext }: GraphqlResults) => {
  return (
    <div>
      <SEO title="About" />

      <NarrativeRow image={bookStackImage}
                    imagePosition={ImagePosition.LEFT}>
        We are a small publisher for independent authors, but don't let our size
        fool you. We're just getting started.
      </NarrativeRow>

      <NarrativeRow
        image={bookStackWithOpenBook}
        imagePosition={ImagePosition.RIGHT}
      >
        Today, we offer nonfiction children books that are beautifully
        illustrated, packed with information easily understood by children and
        educationally satisfying to parents. Our children's books will open your
        child's eyes to the wonderful world of nature and the fascinating
        creatures who live in the wild from around the world.
      </NarrativeRow>

      <NarrativeRow image={booksWithCoffeeCup}
                    imagePosition={ImagePosition.LEFT}>
        In the future, we'll offer books for young and old alike. Soon, we'll
        offer titles in a range of nonfiction genres. We're just at the
        beginning and hope you'll join us on this exciting journey!
      </NarrativeRow>

      <NarrativeRow image={boyHikerOnBook} imagePosition={ImagePosition.RIGHT}>
        Sign up for our newsletter to be among the first to learn about new
        titles and bonus offers for being a subscriber.
      </NarrativeRow>

      <NarrativeRow image={balloonBook} imagePosition={ImagePosition.LEFT}>
        Visit our <Link to='/contact'>Contact</Link> page to let us know how we're doing,
        make a suggestion or just say hello.
      </NarrativeRow>

      <AboutSignupForm />
    </div>
  )
}

export default AboutPage
