import {
  createStyles,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import * as React from 'react'
import { Variant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    leftImage: {
      float: 'right',
      paddingRight: 20,
    },
    rightImage: {
      float: 'left',
      paddingLeft: 20,
    },
    shrinkImage: {
      width: 120,
    },
    narrativeRow: {
      margin: 'auto',
      width: '90%',
      marginTop: 10,
      paddingTop: 10,
      '@media (max-width:400px)': {
        textAlign: 'left',
      },
      textAlign: 'justify',
      fontFamily: 'yrsa, serif',
    },
  }),
)

export enum ImagePosition {
  LEFT,
  RIGHT,
  NONE
}

interface NarrativeRowProps {
  image?: any
  children: any
  variant?: Variant
  imagePosition: ImagePosition
}

interface NarrativeProps {
  image?: any
  shrinkImage?: boolean
  children: any
}

const NarrativeRow = (props: NarrativeRowProps) => {
  const classes = useStyles()

  const variant = props.variant ? props.variant : 'body1'

  const imageClasses = (baseStyle: string, shrinkImage: boolean) => (
    shrinkImage ? getClasses(baseStyle, classes.shrinkImage) : baseStyle
  )
  const getClasses = (baseStyles: string, newStyle: string) => {
    const styles = [baseStyles]
    styles.push(newStyle)

    return styles.join(' ')
  }

  const NarrativeLeft = ({ image, shrinkImage, children }: NarrativeProps) => (
    <>
      <Grid item md={9}>
        <Typography variant={variant}>
          {children}
        </Typography>
      </Grid>
      <Grid item md={1} className={imageClasses(classes.rightImage, shrinkImage)}>
        {image}
      </Grid>
    </>
  )

  const NarrativeRight = ({ image, shrinkImage, children }: NarrativeProps) => (
    <>
      <Grid item md={1} className={imageClasses(classes.leftImage, shrinkImage)}>
        {image}
      </Grid>
      <Grid item md={9}>
        <Typography variant={variant}>
          {children}
        </Typography>
      </Grid>
    </>
  )

  const SystemNarrative = ({ children }: NarrativeProps) => (
    <>
      <Grid item md={2}>&nbsp;</Grid>
      <Grid item md={6}>
        <Typography variant={variant}>
          {children}
        </Typography>
      </Grid>
      <Grid item md={2}>&nbsp;</Grid>
    </>
  )

  const NarrativeRowContent = ({
                                 image,
                                 children,
                                 imagePosition,
                               }: NarrativeRowProps) => {
    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))

    let content: any
    switch (imagePosition) {
      case ImagePosition.LEFT:
        content = <NarrativeRight image={image} shrinkImage={smDown} children={children} />
        break
      case ImagePosition.RIGHT:
        content = mdDown
          ? <NarrativeRight image={image} shrinkImage={smDown} children={children} />
          : <NarrativeLeft image={image} shrinkImage={smDown} children={children} />
        break
      case ImagePosition.NONE:
      default:
        content = <SystemNarrative>{children}</SystemNarrative>
    }

    return content
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      alignContent='center'
      className={classes.narrativeRow}
    >
      <Grid item md={1}>
        &nbsp;
      </Grid>
      <NarrativeRowContent {...props}>{props.children}</NarrativeRowContent>
      <Grid item md={1}>
        &nbsp;
      </Grid>
    </Grid>
  )
}

export default NarrativeRow
